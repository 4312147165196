/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
// eslint-disable-next-line
import moment, { locale } from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMediaQuery } from "react-responsive";
// eslint-disable-next-line
import "moment/locale/fr";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchWithInterceptors } from "../../../functions/fetchWithInterceptors";
import style from "./UserPlanning.module.scss";
// eslint-disable-next-line
moment.locale("fr");

const UserPlanning = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const localizer = momentLocalizer(moment);
  const user_id = localStorage.getItem("user_id");
  const [plannerData, setPlannerData] = useState([]);
  const [sessionsGet, setSessionsGet] = useState([]);
  const [username, setUserName] = useState([]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  useEffect(() => {
    fetchWithInterceptors(`${API_URL}/users/${user_id}/registrations/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((data) => {
        if (data) {
          setPlannerData(data);
        } else {
          toast.error("Les données n'ont pas pu être récupérées");
        }
      })
      .catch(() => console.error("Error"));
  }, [user_id]);

  useEffect(() => {
    plannerData.length > 0 &&
      plannerData.map((plan) => {
        fetchWithInterceptors(`${API_URL}/sessions/${plan.session_id}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then((data2) => {
          if (data2) {
            setSessionsGet((previousSessions) => {
              if (
                previousSessions.some(
                  (session) => session.session_id === data2.session_id
                )
              ) {
                return previousSessions;
              } else {
                return [...previousSessions, data2];
              }
            });
          } else {
            toast.error("Les données n'ont pas pu être récupérées");
          }
        });
      });
  }, [plannerData]);

  useEffect(() => {
    username &&
      username.map((plan) => {
        fetchWithInterceptors(`${API_URL}/sessions/${plan.session_id}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then((data2) => {
          if (data2) {
            setUserName((previousSessions) => {
              if (
                previousSessions.some(
                  (session) => session.user_id === data2.user_id
                )
              ) {
                return previousSessions;
              } else {
                return [...previousSessions, data2];
              }
            });
          } else {
            toast.error("Les séances n'ont pas pu être récupérées");
          }
        });
      });
  }, [username]);

  const events = sessionsGet.map((session) => {
    const datePart = session.date.split("T")[0];
    const startTime = new Date(datePart + "T" + session.time);
    return {
      title: `${session.title} - ${session.location}`,
      start: startTime,
      end: new Date(startTime.getTime() + session.duration * 60 * 1000),
      allDay: false,
      resource: session,
      availableSpots: session.availablespots,
    };
  });

  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [registration, setRegistration] = useState([]);

  const handleOpen = (event) => {
    setSelectedEvent(event.resource);
    setOpen(true);

    const currentEventId = event.resource.session_id;

    fetchWithInterceptors(
      `${API_URL}/registrations/getid/user/${user_id}/session/${currentEventId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((data) => {
      if (data) {
        setRegistration(data);
      } else {
        toast.error("Les données n'ont pas pu être récupérées");
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnsubscribe = () => {
    const currentRegistrationId = registration.registration_id;

    fetchWithInterceptors(`${API_URL}/registrations/${currentRegistrationId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((data) => {
      if (data) {
        toast.success("Vous êtes bien désinscrit");
        setTimeout(() => {
          window.location.reload();
        }, [1500]);
        handleClose();
      } else {
        toast.error("Une erreur est survenue, veuillez réessayer");
      }
    });
  };

  const EventComponent = ({ event }) => {
    const { title, location } = event.resource;
    return (
      <div>
        <div>{title}</div>
        <div>{location}</div>
      </div>
    );
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        events={events}
        defaultView={isDesktopOrLaptop ? "week" : "day"}
        min={new Date(0, 0, 0, 8, 0)}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "70vh", margin: "20px" }}
        onSelectEvent={handleOpen}
        messages={{
          next: "Suivant",
          previous: "Précédent",
          today: "Aujourd'hui",
          month: "Mois",
          week: "Semaine",
          day: "Jour",
          agenda: "Agenda",
          date: "Date",
          time: "Heure",
          event: "Événement",
          showMore: (total) => `+ Voir plus (${total})`,
        }}
        components={{
          event: EventComponent,
        }}
      />
      <p style={{ textAlign: "center", fontStyle: "italic" }}>
        N&apos;oubliez pas d&apos;amener avec vous une tenue adaptée à la
        pratique sportive lors de vos séances.
      </p>
      <Dialog open={open} onClose={handleClose} className={style.dialogue}>
        <DialogTitle className={style.dialogue_titre}>
          {selectedEvent?.title}
        </DialogTitle>
        <DialogContent className={style.dialogue_content}>
          <DialogContentText className={style.dialogue_content_text}>
            <b>Description</b> : {selectedEvent?.description}
            <br />
            <b>📅 Date</b> :{" "}
            {new Date(selectedEvent?.date).toLocaleDateString()}
            <br />
            <b>⏰ Heure de début</b> : {selectedEvent?.time.split(":")[0]}h
            {selectedEvent?.time.split(":")[1]}
            <br />
            <b>📍 Lieu</b> : {selectedEvent?.location}
            <br />
            <b>⏱️ Durée</b> : {selectedEvent?.duration} minutes
            <br />
            📞 Contact sur site :{" "}
            {selectedEvent?.contact ? selectedEvent?.contact : "Non renseigné"}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={style.dialogue_actions}>
          <button
            onClick={handleUnsubscribe}
            className={style.dialogue_actions_button}
          >
            Se désinscrire
          </button>
          <button
            className={style.dialogue_actions_Button}
            onClick={handleClose}
          >
            Fermer
          </button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        limit={1}
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
};

export default UserPlanning;
