/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
// eslint-disable-next-line
// eslint-disable-next-line
import "moment/locale/fr";

import { toast } from "react-toastify";
// import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { fetchWithInterceptors } from "../../functions/fetchWithInterceptors";
import CoachPlanning from "../../components/Planning/CoachPlanning/CoachPlanning";
import UserPlanning from "../../components/Planning/UserPlanning/UserPlanning";
// eslint-disable-next-line

const MyCalendar = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [roleData, setRoleData] = useState([]);

  useEffect(() => {
    document.title = "Mon Planning - CoachingHub";
  }, []);

  useEffect(() => {
    fetchWithInterceptors(`${API_URL}/roles/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")} `,
      },
    })
      .then((data) => {
        if (data) {
          setRoleData(data);
        } else {
          toast.error("Une erreur est survenue");
          localStorage.clear();
          setTimeout(() => {
            navigate("/login");
          }, "1500");
        }
      })
      .catch(() => console.error("Error"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeOfUserLogged = localStorage.getItem("user_role");

  const [isUser, setIsUser] = useState();
  const [isCoach, setIsCoach] = useState();
  const [isAdmin, setIsAdmin] = useState();

  useEffect(() => {
    if (roleData && roleData.length >= 1) {
      setIsUser(roleData[0].role_id);
      setIsCoach(roleData[1].role_id);
      setIsAdmin(roleData[2].role_id);
    }
  }, [roleData]);

  const [userIsLogged, setUserIsLogged] = useState(false);
  const [coachIsLogged, setCoachIsLogged] = useState(false);
  const [adminIsLogged, setAdminIsLogged] = useState(false);

  useEffect(() => {
    switch (typeOfUserLogged) {
      case isUser:
        if (!userIsLogged) {
          setUserIsLogged(true);
          setAdminIsLogged(false);
          setCoachIsLogged(false);
        }
        break;
      case isCoach:
        if (!userIsLogged) {
          setUserIsLogged(false);
          setAdminIsLogged(false);
          setCoachIsLogged(true);
        }
        break;
      case isAdmin:
        if (!userIsLogged) {
          setUserIsLogged(false);
          setAdminIsLogged(true);
          setCoachIsLogged(false);
        }
        break;
    }
  }, [typeOfUserLogged, isUser, isCoach, isAdmin, userIsLogged]);

  if (userIsLogged || adminIsLogged) {
    return <UserPlanning />;
  } else if (coachIsLogged) {
    return <CoachPlanning />;
  }
};

export default MyCalendar;
