import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import style from "./App.module.scss";
import Footer from "./components/footer/Footer";
import Topbar from "./components/topbar/Topbar";
import { HomePage } from "./pages/HomePage/HomePage";
import { LoginPage } from "./pages/Login/Login";
import { MentionsLegales } from "./pages/MentionsLegales/MentionsLegales";
import { SignUpPage } from "./pages/SignUp/SignUp";
import Planning from "./pages/Planning/Planning";
import Inscription from "./pages/Inscription/Inscription";
import { History } from "./pages/History/HistoryPage";
import { AdminPage } from "./pages/Admin/Admin";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import InfoBanner from "./components/InfoBanner/InfoBanner";
import { useEffect, useState } from "react";
import { Profil } from "./pages/Profil/Profil";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { fetchWithInterceptors } from "./functions/fetchWithInterceptors";
import { LoginAfterExpired } from "./pages/Login/loginAfterExpired";
import { AccessCodePage } from "./pages/AccessCode/AccessCode";
import LoadingComponent from "./pages/LoadingPage";

const pagesCoach = [
  {
    Nom: "Accueil",
    Chemin: "/accueil",
  },
  {
    Nom: "Planning",
    Chemin: "/planning",
  },
  {
    Nom: "Historique",
    Chemin: "/historique",
  },
];

const pages = [
  {
    Nom: "Accueil",
    Chemin: "/accueil",
  },
  {
    Nom: "Planning",
    Chemin: "/planning",
  },
  {
    Nom: "Historique",
    Chemin: "/historique",
  },
  {
    Nom: "Inscription",
    Chemin: "/inscription",
  },
];
const pagesAdmin = [
  {
    Nom: "Accueil",
    Chemin: "/accueil",
  },
  {
    Nom: "Planning",
    Chemin: "/planning",
  },
  {
    Nom: "Historique",
    Chemin: "/historique",
  },
  {
    Nom: "Inscription",
    Chemin: "/inscription",
  },
  {
    Nom: "Administration",
    Chemin: "/admin",
  },
];

const RouteRenderer = ({ path }) => {
  switch (path) {
    case "/accueil":
      return <HomePage />;
    case "/planning":
      return <Planning />;
    case "/historique":
      return <History />;
    case "/inscription":
      return <Inscription />;
    case "/admin":
      return <AdminPage />;
    case "/profil":
      return <Profil />;
    default:
      return null;
  }
};

const MainRoutes = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [roleData, setRoleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const typeOfUserLogged = localStorage.getItem("user_role");

  const [isUser, setIsUser] = useState();
  const [isCoach, setIsCoach] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [userIsLogged, setUserIsLogged] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [coachIsLogged, setCoachIsLogged] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [adminIsLogged, setAdminIsLogged] = useState(false);

  useEffect(() => {
    if (roleData && roleData.length >= 1) {
      setIsUser(roleData[0].role_id);
      setIsCoach(roleData[1].role_id);
      setIsAdmin(roleData[2].role_id);
    }
  }, [roleData]);

  useEffect(() => {
    switch (typeOfUserLogged) {
      case isUser:
        if (!userIsLogged) {
          setUserIsLogged(true);
          setAdminIsLogged(false);
          setCoachIsLogged(false);
        }
        break;
      case isCoach:
        if (!userIsLogged) {
          setUserIsLogged(false);
          setAdminIsLogged(false);
          setCoachIsLogged(true);
        }
        break;
      case isAdmin:
        if (!userIsLogged) {
          setUserIsLogged(false);
          setAdminIsLogged(true);
          setCoachIsLogged(false);
        }
        break;
    }
  }, [typeOfUserLogged, isUser, isCoach, isAdmin, userIsLogged]);

  let allowedRoutes = [];

  useEffect(() => {
    setIsLoading(true);
    fetchWithInterceptors(`${API_URL}/roles/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data) {
          setRoleData(data);
          setIsLoading(false);
        } else {
          toast.error("Une erreur est survenue");
          localStorage.clear();
          setTimeout(() => {
            navigate("/login");
          }, "1500");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (typeOfUserLogged === String(isUser)) {
    allowedRoutes = pages;
  } else if (typeOfUserLogged === String(isCoach)) {
    allowedRoutes = pagesCoach;
  } else if (typeOfUserLogged === String(isAdmin)) {
    allowedRoutes = pagesAdmin;
  }

  return (
    <>
      <Topbar />
      <InfoBanner />
      <Routes>
        {allowedRoutes.map((route) => (
          <Route
            key={route.Chemin}
            path={route.Chemin}
            element={<RouteRenderer path={route.Chemin} />}
          />
        ))}
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/profil" element={<Profil />} />
        <Route path="*" element={<LoadingComponent />} />
      </Routes>
      <Footer />
    </>
  );
};

export default function App() {
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const accessCodeTimestamp = localStorage.getItem("accessCodeTimestamp");
    const oneHourInMilliseconds = 14400000; // 4h

    if (
      accessCodeTimestamp &&
      new Date().getTime() - accessCodeTimestamp < oneHourInMilliseconds
    ) {
      setHasAccess(true);
    }
  }, []);

  const handleValidCode = () => {
    const currentTimestamp = new Date().getTime();
    localStorage.setItem("accessCodeTimestamp", currentTimestamp);
    setHasAccess(true);
  };

  return (
    <Router>
      <main className={style["App"]}>
        {!hasAccess ? (
          <AccessCodePage onValidCode={handleValidCode} />
        ) : (
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/loginAfterExpired" element={<LoginAfterExpired />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/*" element={<MainRoutes />} />
          </Routes>
        )}
      </main>
      <ToastContainer
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Router>
  );
}
