import style from "./Dashboard.module.scss";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchWithInterceptors } from "../../../functions/fetchWithInterceptors";

const Dashboard = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [data, setData] = useState({});

  useEffect(() => {
    fetchWithInterceptors(`${API_URL}/Admin/dashboard/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")} `,
      },
    })
      .then((responseData) => {
        if (responseData) {
          setData(responseData);
        } else {
          toast.error("Les données n'ont pas pu être récupérées");
        }
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <div className={style["Dashboard"]}>
      <h2>Dashboard</h2>
      <div>
        <h3>Nombre d&apos;utilisateurs</h3>
        {data.total_users}
      </div>
      <div>
        <h3>Nombre de Séances</h3>
        {data.total_sessions}
      </div>
      <div>
        <h3>Nombre d&apos;inscriptions</h3>
        {data.total_registrations}
      </div>
      <div>
        <h3>Feedbacks</h3>
        {data.total_reports}
      </div>
      <div>
        <h3>Répartition par tranche d&apos;âge</h3>
        <ul>
          {data.agePercentages &&
            data.agePercentages.map((item, index) => (
              <li key={index}>
                {item.age}: {parseFloat(item.percentage).toFixed(2)}%
              </li>
            ))}
        </ul>
      </div>
      <div>
        <h3>Tranche d&apos;âge la plus participative</h3>
        {data.mostParticipationAgeGroup?.age}:{" "}
        {data.mostParticipationAgeGroup?.total_registrations} inscriptions
      </div>
      <div>
        <h3>Tranche d&apos;âge la moins participative</h3>
        {data.leastParticipationAgeGroup?.age}:{" "}
        {data.leastParticipationAgeGroup?.total_registrations} inscriptions
      </div>
      <ToastContainer
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};

export default Dashboard;
