import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { fetchWithInterceptors } from "../../../functions/fetchWithInterceptors";
import { Calendar, momentLocalizer } from "react-big-calendar";
// eslint-disable-next-line
import moment, { locale } from "moment";
// eslint-disable-next-line
import { useMediaQuery } from "react-responsive";
import "moment/locale/fr";
import style from "./CoachPlanning.module.scss";

// eslint-disable-next-line
moment.locale("fr");

const CoachPlanning = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);
  const user_id = localStorage.getItem("user_id");
  const [plannerData, setPlannerData] = useState([]);
  const [sessionsGet, setSessionsGet] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [registration, setRegistration] = useState([]);

  useEffect(() => {
    fetchWithInterceptors(`${API_URL}/registrations/coach/${user_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((data) => {
        data
          ? setPlannerData(data)
          : toast.error("Les données n'ont pas pu être récupérées");
      })
      .catch(() => console.error("Error"));
  }, []);

  useEffect(() => {
    plannerData.length > 0 &&
      plannerData.map((plan) => {
        fetchWithInterceptors(`${API_URL}/sessions/${plan.session_id}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then((data2) => {
          if (data2) {
            setSessionsGet((previousSessions) => {
              if (
                previousSessions.some(
                  (session) => session.session_id === data2.session_id
                )
              ) {
                return previousSessions;
              } else {
                return [...previousSessions, data2];
              }
            });
          } else {
            toast.error("Les données n'ont pas pu être récupérées");
          }
        });
      });
  }, [plannerData]);

  const events = sessionsGet.map((session) => {
    const datePart = session.date.split("T")[0];
    const startTime = new Date(datePart + "T" + session.time);
    return {
      title: `${session.title} - ${session.location}`,
      start: startTime,
      end: new Date(startTime.getTime() + session.duration * 60 * 1000),
      allDay: false,
      resource: session,
      availableSpots: session.availablespots,
    };
  });

  const EventComponent = ({ event }) => {
    const { title, location } = event.resource;
    return (
      <div>
        <div>{title}</div>
        <div>{location}</div>
      </div>
    );
  };

  const handleOpen = (event) => {
    setSelectedEvent(event.resource);
    setOpen(true);

    const currentEventId = event.resource.session_id;

    fetchWithInterceptors(`${API_URL}/sessions/${currentEventId}/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")} `,
      },
    }).then((data) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          fetchWithInterceptors(`${API_URL}/users/${data[i].user_id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")} `,
            },
          }).then((data2) => {
            setRowData((previousUser) => {
              if (previousUser.some((user) => user.user_id === data2.user_id)) {
                return previousUser;
              } else {
                return [...previousUser, data2];
              }
            });
          });
        }
      } else {
        toast.error("Une erreur est survenue");
        localStorage.clear();
        setTimeout(() => {
          navigate("/login");
        }, "1500");
      }
    });

    fetchWithInterceptors(
      `${API_URL}/registrations/getid/coach/session/${currentEventId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((data) => {
      if (data) {
        setRegistration(data);
      } else {
        toast.error("Les données n'ont pas pu être récupérées");
      }
    });
  };

  const handleClose = () => {
    setRowData([]);
    setOpen(false);
  };

  // Tableau temporaire qui stocke les données de l'appel avec user_id et attended
  const [tempAttendance, setTempAttendance] = useState([]);

  // D'abord, remplir le tableau temporaire avec les users inscrits à la session
  useEffect(() => {
    if (rowData && rowData.length > 0) {
      rowData.map((element) => {
        setTempAttendance((previousAttendance) => {
          if (
            previousAttendance.some(
              (attendance) => attendance.attendedUser_id === element.user_id
            )
          ) {
            return previousAttendance;
          } else {
            return [
              ...previousAttendance,
              {
                attendedUser_id: element.user_id,
                attended: false,
                registration_id: registration.registration_id,
              },
            ];
          }
        });
      });
    }
  }, [rowData]);

  // Fonction qui set attended à true ou false quand la checkbox est cochée ou décochée pour chaque user (éxécutée à chaque changement de la valeur d'une checkbox pour l'utilisateur correspondant)
  const isCheckboxChecked = (e) => {
    const currentRegistrationId = registration.registration_id;
    const user_id = e.target.value;
    const attended = e.target.checked;
    // Ensuite : si le user_id est déjà présent dans le tableau temporaire, on met à jour la valeur de attended
    setTempAttendance((previousAttendance) => {
      if (
        previousAttendance.some(
          (attendance) => attendance.attendedUser_id === user_id
        )
      ) {
        return previousAttendance.map((attendance) => {
          if (attendance.attendedUser_id === user_id) {
            return {
              ...attendance,
              attended: attended,
            };
          } else {
            return attendance;
          }
        });
      } else {
        return [
          ...previousAttendance,
          {
            attendedUser_id: user_id,
            attended: attended,
            registration_id: currentRegistrationId,
          },
        ];
      }
    });
  };

  // Fonction qui onSubmit soumet l'appel à l'api
  const handleSubmitAttendance = (e) => {
    const currentRegistrationId = registration.registration_id;
    e.preventDefault();
    tempAttendance &&
      tempAttendance.map((element) => {
        fetchWithInterceptors(`${API_URL}/registrations/attended`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            attended: element.attended,
            registration_id: currentRegistrationId,
          }),
        }).then((data) => {
          if (data) {
            toast.success("L'appel a bien été soumis");
            setTempAttendance([]);
          } else {
            toast.error("Erreur lors de la soumition");
          }
        });
      });
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        events={events}
        defaultView={isDesktopOrLaptop ? "week" : "day"}
        min={new Date(0, 0, 0, 8, 0)}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "70vh", margin: "20px" }}
        onSelectEvent={handleOpen}
        messages={{
          next: "Suivant",
          previous: "Précédent",
          today: "Aujourd'hui",
          month: "Mois",
          week: "Semaine",
          day: "Jour",
          agenda: "Agenda",
          date: "Date",
          time: "Heure",
          event: "Événement",
          showMore: (total) => `+ Voir plus (${total})`,
        }}
        components={{
          event: EventComponent,
        }}
      />
      <Dialog open={open} onClose={handleClose} className={style.dialogue}>
        <DialogTitle className={style.dialogue_titre}>
          {selectedEvent?.title}
        </DialogTitle>
        <DialogContent className={style.dialogue_content}>
          <DialogContentText className={style.dialogue_content_text}>
            <b>Description</b> : {selectedEvent?.description}
            <br />
            <b>📅 Date</b> :{" "}
            {new Date(selectedEvent?.date).toLocaleDateString()}
            <br />
            <b>⏰ Heure de début</b> : {selectedEvent?.time.split(":")[0]}h
            {selectedEvent?.time.split(":")[1]}
            <br />
            <b>📍 Lieu</b> : {selectedEvent?.location}
            <br />
            <b>⏱️ Durée</b> : {selectedEvent?.duration} minutes
            <br />
            📞 Contact Collecty&apos;form : Alexandre / 06 02 37 21 37
            <br />
            📞 Contact sur site :{" "}
            {selectedEvent?.contact ? selectedEvent?.contact : "Non renseigné"}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="ag-theme-alpine"
                style={{
                  height: "100%",
                  width: "80%",
                  marginTop: "40px",
                  "--ag-alpine-active-color": "#23274f",
                  "--ag-data-color": "#23274f",
                  "--ag-header-foreground-color": "#23274f",
                }}
              >
                <h2 className={style["session-title"]}>
                  Participants inscrits
                </h2>
                <h5 className={style["session-subtitle"]}>
                  Visualisez les participants inscrits à la session.
                </h5>
                {rowData &&
                  rowData.map((element) => {
                    return (
                      <form key={element.firstname}>
                        <table>
                          <thead>
                            <tr>
                              <th>Prénom</th>
                              <th>Pseudo</th>
                              <th>Âge</th>
                              <th>Présent</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{element.firstname}</td>
                              <td>{element.pseudo}</td>
                              <td>{element.age}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={tempAttendance.some(
                                    (attendance) =>
                                      attendance.attendedUser_id ===
                                        element.user_id &&
                                      attendance.attended === true
                                  )}
                                  onChange={isCheckboxChecked}
                                  value={element.user_id}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <button onClick={handleSubmitAttendance}>
                          Soumettre
                        </button>
                      </form>
                    );
                  })}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={style.dialogue_actions}>
          <button
            className={style.dialogue_actions_Button}
            onClick={handleClose}
          >
            Fermer
          </button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        limit={1}
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
};

export default CoachPlanning;
