// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PagesDetails_tabs__mWHwh {
  margin-top: 40px;
}
.PagesDetails_tabs__mWHwh .PagesDetails_tab__rcMW5 {
  margin-bottom: 20px;
}

.PagesDetails_footer__HvfnN {
  margin-top: 40px;
  text-align: center;
}
.PagesDetails_footer__HvfnN .PagesDetails_button__trxzp {
  background: #23274f;
  border: none;
  border-radius: 16px;
  padding: 10px 20px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 10px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: 500;
}
.PagesDetails_footer__HvfnN .PagesDetails_button__trxzp:hover {
  background: #1a1d3a;
  transform: scale(1.1);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}
.PagesDetails_footer__HvfnN .PagesDetails_button__trxzp a {
  color: white;
  text-decoration: none;
}
.PagesDetails_footer__HvfnN h3 {
  font-size: 1.6rem;
}
.PagesDetails_footer__HvfnN h3 span {
  color: #f4a261;
}`, "",{"version":3,"sources":["webpack://./src/components/Homepage/PagesDetails/PagesDetails.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,gBAAA;EACA,kBAAA;AAAF;AAEE;EACE,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,0CAAA;EACA,gBAAA;AAAJ;AAEI;EACE,mBAAA;EACA,qBAAA;EACA,2CAAA;AAAN;AAGI;EACE,YAAA;EACA,qBAAA;AADN;AAKE;EACE,iBAAA;AAHJ;AAKI;EACE,cAAA;AAHN","sourcesContent":[".tabs {\n  margin-top: 40px;\n\n  .tab {\n    margin-bottom: 20px;\n  }\n}\n\n.footer {\n  margin-top: 40px;\n  text-align: center;\n\n  .button {\n    background: #23274f;\n    border: none;\n    border-radius: 16px;\n    padding: 10px 20px;\n    color: white;\n    font-size: 1rem;\n    cursor: pointer;\n    margin-left: 10px;\n    transition: all 0.2s ease-in-out;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n    font-weight: 500;\n\n    &:hover {\n      background: #1a1d3a;\n      transform: scale(1.1);\n      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);\n    }\n\n    a {\n      color: white;\n      text-decoration: none;\n    }\n  }\n\n  h3 {\n    font-size: 1.6rem;\n\n    span {\n      color: #f4a261;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `PagesDetails_tabs__mWHwh`,
	"tab": `PagesDetails_tab__rcMW5`,
	"footer": `PagesDetails_footer__HvfnN`,
	"button": `PagesDetails_button__trxzp`
};
export default ___CSS_LOADER_EXPORT___;
