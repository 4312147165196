// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MentionsLegales_MentionsLegales__gYMQi {
  max-width: 1200px;
  margin: 10px auto;
  padding: 0 1vw;
}
.MentionsLegales_MentionsLegales__gYMQi h1,
.MentionsLegales_MentionsLegales__gYMQi h2,
.MentionsLegales_MentionsLegales__gYMQi h3,
.MentionsLegales_MentionsLegales__gYMQi h4,
.MentionsLegales_MentionsLegales__gYMQi h5,
.MentionsLegales_MentionsLegales__gYMQi h6 {
  color: #23274f;
}`, "",{"version":3,"sources":["webpack://./src/pages/MentionsLegales/MentionsLegales.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,iBAAA;EACA,cAAA;AACF;AACE;;;;;;EAME,cAAA;AACJ","sourcesContent":[".MentionsLegales {\n  max-width: 1200px;\n  margin: 10px auto;\n  padding: 0 1vw;\n\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    color: #23274f;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MentionsLegales": `MentionsLegales_MentionsLegales__gYMQi`
};
export default ___CSS_LOADER_EXPORT___;
