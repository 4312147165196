// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccessCode_containercodeaccess__yIdY\\+ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Arial", sans-serif;
  background-color: #f3f4f6;
}

.AccessCode_imagelogo__BfEkz {
  height: 70px;
  margin-bottom: 2em;
}

.AccessCode_inputcodeaccess__B6wm1 {
  padding: 0.5em;
  margin: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  width: 80%;
  max-width: 300px;
  outline: none;
  transition: border-color 0.3s;
}

.AccessCode_inputcodeaccess__B6wm1:focus {
  border-color: #007BFF;
}

.AccessCode_buttonaccess__VFyqB {
  padding: 0.5em 1em;
  margin-top: 1em;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.AccessCode_buttonaccess__VFyqB:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/pages/AccessCode/AccessCode.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,gCAAA;EACA,yBAAA;AACJ;;AAEA;EACI,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,cAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,cAAA;EACA,UAAA;EACA,gBAAA;EACA,aAAA;EACA,6BAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;EACA,iCAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".containercodeaccess {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; \n    font-family: 'Arial', sans-serif;\n    background-color: #f3f4f6; \n}\n\n.imagelogo {\n    height: 70px;    \n    margin-bottom: 2em;\n}\n\n.inputcodeaccess {\n    padding: 0.5em;\n    margin: 0.5em;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 1em;\n    width: 80%;\n    max-width: 300px;\n    outline: none;\n    transition: border-color 0.3s;\n}\n\n.inputcodeaccess:focus {\n    border-color: #007BFF;\n}\n\n.buttonaccess {\n    padding: 0.5em 1em;\n    margin-top: 1em;\n    background-color: #007BFF;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 1em;\n    transition: background-color 0.3s;\n}\n\n.buttonaccess:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containercodeaccess": `AccessCode_containercodeaccess__yIdY+`,
	"imagelogo": `AccessCode_imagelogo__BfEkz`,
	"inputcodeaccess": `AccessCode_inputcodeaccess__B6wm1`,
	"buttonaccess": `AccessCode_buttonaccess__VFyqB`
};
export default ___CSS_LOADER_EXPORT___;
