// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalSession_modal-content__xtiJ4 {
  width: 350px;
  height: 500px;
  display: flex;
  flex-direction: column;
}
.ModalSession_modal-content__xtiJ4 .ModalSession_detailsContainer__H5OJD {
  height: 75%;
  margin: 0 16px;
}
.ModalSession_modal-content__xtiJ4 h2 {
  color: #23274f;
  font-size: 26px;
  text-align: center;
  height: 25%;
  -webkit-text-decoration: underline #23274f;
          text-decoration: underline #23274f;
  text-underline-offset: 0.25em;
}
.ModalSession_modal-content__xtiJ4 .ModalSession_highNumberOfAvailableSpots__1aMOS {
  color: #23274f;
  margin-bottom: 10px;
  font-size: 20px;
}
.ModalSession_modal-content__xtiJ4 .ModalSession_lowNumberOfAvailableSpots__d2H0i {
  color: #ff3333;
  margin-bottom: 10px;
  font-size: 20px;
}
.ModalSession_modal-content__xtiJ4 p {
  color: #23274f;
  margin-bottom: 10px;
  font-size: 20px;
}
.ModalSession_modal-content__xtiJ4 .ModalSession_submitBox__nTsVR {
  height: 25%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.ModalSession_modal-content__xtiJ4 .ModalSession_modalInput__XlGSP {
  margin-bottom: 20px;
  color: #23274f;
}
.ModalSession_modal-content__xtiJ4 .ModalSession_submitButton__WljHk {
  font-size: 20px;
  margin: auto;
  color: #fff;
  background-color: #23274f;
  border: none;
  border-radius: 10px;
  padding: 7px 15px;
  transition: 250ms;
  cursor: pointer;
}
.ModalSession_modal-content__xtiJ4 .ModalSession_submitButton__WljHk:disabled {
  background-color: #ff3333;
  transition: 250ms;
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalSession/ModalSession.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;EACA,cAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,0CAAA;UAAA,kCAAA;EACA,6BAAA;AAAJ;AAGE;EACE,cAAA;EACA,mBAAA;EACA,eAAA;AADJ;AAIE;EACE,cAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAKE;EACE,cAAA;EACA,mBAAA;EACA,eAAA;AAHJ;AAME;EACE,WAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AAJJ;AAOE;EACE,mBAAA;EACA,cAAA;AALJ;AAQE;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;AANJ;AASE;EACE,yBAAA;EACA,iBAAA;EACA,eAAA;AAPJ","sourcesContent":[".modal-content {\n  width: 350px;\n  height: 500px;\n  display: flex;\n  flex-direction: column;\n\n  .detailsContainer {\n    height: 75%;\n    margin: 0 16px;\n  }\n\n  h2 {\n    color: #23274f;\n    font-size: 26px;\n    text-align: center;\n    height: 25%;\n    text-decoration: underline #23274f;\n    text-underline-offset: 0.25em;\n  }\n\n  .highNumberOfAvailableSpots {\n    color: #23274f;\n    margin-bottom: 10px;\n    font-size: 20px;\n  }\n\n  .lowNumberOfAvailableSpots {\n    color: #ff3333;\n    margin-bottom: 10px;\n    font-size: 20px;\n  }\n\n  p {\n    color: #23274f;\n    margin-bottom: 10px;\n    font-size: 20px;\n  }\n\n  .submitBox {\n    height: 25%;\n    width: 100%;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n  }\n\n  .modalInput {\n    margin-bottom: 20px;\n    color: #23274f;\n  }\n\n  .submitButton {\n    font-size: 20px;\n    margin: auto;\n    color: #fff;\n    background-color: #23274f;\n    border: none;\n    border-radius: 10px;\n    padding: 7px 15px;\n    transition: 250ms;\n    cursor: pointer;\n  }\n\n  .submitButton:disabled {\n    background-color: #ff3333;\n    transition: 250ms;\n    cursor: default;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-content": `ModalSession_modal-content__xtiJ4`,
	"detailsContainer": `ModalSession_detailsContainer__H5OJD`,
	"highNumberOfAvailableSpots": `ModalSession_highNumberOfAvailableSpots__1aMOS`,
	"lowNumberOfAvailableSpots": `ModalSession_lowNumberOfAvailableSpots__d2H0i`,
	"submitBox": `ModalSession_submitBox__nTsVR`,
	"modalInput": `ModalSession_modalInput__XlGSP`,
	"submitButton": `ModalSession_submitButton__WljHk`
};
export default ___CSS_LOADER_EXPORT___;
