// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes HomePage_fadeIn__xfNpk {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.HomePage_HomePage__yyvUc {
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  animation: HomePage_fadeIn__xfNpk 0.6s ease-in-out;
}
.HomePage_HomePage__yyvUc .HomePage_tabs__nqey3 {
  margin-top: 40px;
}
.HomePage_HomePage__yyvUc .HomePage_tabs__nqey3 .HomePage_tab__irAZG {
  margin-bottom: 20px;
}
.HomePage_HomePage__yyvUc .HomePage_footer__K4uNR {
  margin-top: 40px;
  text-align: center;
}
.HomePage_HomePage__yyvUc .HomePage_footer__K4uNR .HomePage_button__sxVjE {
  background: #23274f;
  border: none;
  border-radius: 16px;
  padding: 10px 20px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 10px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.HomePage_HomePage__yyvUc .HomePage_footer__K4uNR .HomePage_button__sxVjE:hover {
  background: #1a1d3a;
  transform: scale(1.1);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}
.HomePage_HomePage__yyvUc .HomePage_footer__K4uNR .HomePage_button__sxVjE a {
  color: white;
  text-decoration: none;
}
.HomePage_HomePage__yyvUc .HomePage_footer__K4uNR h3 {
  font-size: 1.6rem;
}
.HomePage_HomePage__yyvUc .HomePage_footer__K4uNR h3 span {
  color: #f4a261;
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.module.scss"],"names":[],"mappings":"AACA;EACE;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF;AAGA;EACE,iBAAA;EACA,cAAA;EACA,gCAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,kDAAA;AADF;AAGE;EACE,gBAAA;AADJ;AAGI;EACE,mBAAA;AADN;AAKE;EACE,gBAAA;EACA,kBAAA;AAHJ;AAKI;EACE,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,0CAAA;AAHN;AAKM;EACE,mBAAA;EACA,qBAAA;EACA,2CAAA;AAHR;AAMM;EACE,YAAA;EACA,qBAAA;AAJR;AAQI;EACE,iBAAA;AANN;AAQM;EACE,cAAA;AANR","sourcesContent":["// Animation de base au chargement de la page\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.HomePage {\n  max-width: 1200px;\n  margin: 0 auto;\n  font-family: \"Arial\", sans-serif;\n  line-height: 1.6;\n  color: #333;\n  padding: 20px;\n  animation: fadeIn 0.6s ease-in-out;\n\n  .tabs {\n    margin-top: 40px;\n\n    .tab {\n      margin-bottom: 20px;\n    }\n  }\n\n  .footer {\n    margin-top: 40px;\n    text-align: center;\n\n    .button {\n      background: #23274f;\n      border: none;\n      border-radius: 16px;\n      padding: 10px 20px;\n      color: white;\n      font-size: 1rem;\n      cursor: pointer;\n      margin-left: 10px;\n      transition: all 0.2s ease-in-out;\n      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n\n      &:hover {\n        background: #1a1d3a;\n        transform: scale(1.1);\n        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);\n      }\n\n      a {\n        color: white;\n        text-decoration: none;\n      }\n    }\n\n    h3 {\n      font-size: 1.6rem;\n\n      span {\n        color: #f4a261;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomePage": `HomePage_HomePage__yyvUc`,
	"fadeIn": `HomePage_fadeIn__xfNpk`,
	"tabs": `HomePage_tabs__nqey3`,
	"tab": `HomePage_tab__irAZG`,
	"footer": `HomePage_footer__K4uNR`,
	"button": `HomePage_button__sxVjE`
};
export default ___CSS_LOADER_EXPORT___;
