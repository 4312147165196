import style from "./Footer.module.scss";
import version from "./../../../package.json";

export default function Footer() {
  return (
    <section className={style["footer-main-container"]}>
      <p> Accessibilité : partiellement conforme </p>
      <p>Collecty&apos;form - 2024 - Tous droits réservés</p>
      <a href="/mentions-legales">Mentions légales</a>
      <p>CoachingHub v{version.version}</p>
    </section>
  );
}
