import style from './Introduction.module.scss';
import GenericCoachingPic from "../../../assets/images/coaching_generic_pic.webp";

const Introduction = () => {
  return (
    <div>
      <div className={style.header}>
        <h1>
        🥳 Bienvenue sur le <span>CoachingHub 🥳</span>
        </h1>
      </div>

      <section className={style.intro}>
        <div className={style.text}>
          <p>
            Vous êtes actuellement sur la page d&apos;accueil de CoachingHub,
            votre source fiable pour toutes vos activités de coaching. Cette
            plateforme est conçue pour être intuitive et accessible, offrant une
            variété de fonctionnalités pour optimiser votre expérience de
            coaching. Vous y trouverez des outils pour suivre vos progrès,
            planifier vos séances et même obtenir des conseils de professionnels
            du domaine. N&apos;hésitez pas à explorer toutes les options
            disponibles. Vous êtes libre d&apos;utiliser cette plateforme comme
            bon vous semble, que ce soit pour trouver de nouvelles séances,
            suivre votre historique, ou mettre à jour votre profil.
          </p>
          <small>
            <em>
             ⚠️ N&apos;oubliez pas d&apos;amener une tenue adaptée à la pratique
              sportive lors de vos séances, ainsi qu&apos;une bouteille
              d&apos;eau pour rester hydraté.e. ⚠️
            </em>
          </small>
        </div>
        <div className={style.imageWrapper}>
          <img
            src={GenericCoachingPic}
            alt="Image de coaching"
            className={style.image}
          />
        </div>
      </section>
    </div>
  );
};

export default Introduction;