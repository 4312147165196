import { useState } from "react";
import ModalSession from "../../../components/ModalSession/ModalSession";
import style from "./Cards.module.scss";

const Cards = ({ sessions }) => {
  const [open, setOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const handleOpenModal = (session) => {
    setSelectedSession(session);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedSession(null);
    setOpen(false);
  };

  return (
    <div key={sessions.session_id}>
      <div className={style.grid}>
        {sessions.length > 0 ? (
          sessions.map((session) => (
            <div key={session.id} className={style.gridItem}>
              <div
                style={{
                  height: "15%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <ul
                  style={{
                    display: "flex",
                    gap: "1rem",
                    listStyleType: "none",
                    margin: "0px 20px",
                    paddingLeft: "0",
                  }}
                >
                  <li> 📆 {new Date(session.date).toLocaleDateString()} </li>
                  <li>
                    ⌚ {session.time.split(":")[0]}h{session.time.split(":")[1]}{" "}
                  </li>
                  <li>⌛️ {session.duration}min</li>
                </ul>
              </div>

              <div style={{ height: "75%" }}>
                <h6
                  style={{
                    fontSize: "24px",
                    color: "#23274f",
                    margin: "0 20px",
                    padding: "16px 0",
                  }}
                >
                  {session.title}
                </h6>
                <p
                  style={{
                    fontSize: "20px",
                    color: "#23274f",
                    margin: "10px 20px",
                  }}
                >
                  📍 <strong>{session.location}</strong>
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#23274f",
                    margin: "0 20px",
                  }}
                >
                  {session.description}
                </p>
              </div>
              <button
                className={style["detailsButton"]}
                onClick={() => handleOpenModal(session)}
              >
                Voir les détails 👆
              </button>
            </div>
          ))
        ) : (
          <p
            style={{
              fontSize: "24px",
              color: "#23274f",
              margin: "0 20px",
              padding: "16px 0",
              textAlign: "center",
            }}
          >
            Aucune séance n&apos;est disponible. Essayez de recharger la page ou
            de revenir plus tard.
          </p>
        )}
      </div>
      <ModalSession
        open={open}
        handleCloseModal={handleCloseModal}
        selectedSession={selectedSession}
      />
    </div>
  );
};

export default Cards;
