import * as React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logopp from "./../../assets/logos/logo_pp.png";
import { Link } from "react-router-dom";
import LogoCFDesktop from "./../../assets/logos/LogoCFDesktop.png";
import LogoCFMobile from "./../../assets/logos/LogoCFMobile.png";
import { Divider } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { fetchWithInterceptors } from "../../functions/fetchWithInterceptors";

const pagesCoach = [
  {
    Nom: "Accueil",
    Chemin: "/accueil",
  },
  {
    Nom: "Planning",
    Chemin: "/planning",
  },
  {
    Nom: "Historique",
    Chemin: "/historique",
  },
];

const pages = [
  {
    Nom: "Accueil",
    Chemin: "/accueil",
  },
  {
    Nom: "Planning",
    Chemin: "/planning",
  },
  {
    Nom: "Inscription",
    Chemin: "/inscription",
  },
  {
    Nom: "Historique",
    Chemin: "/historique",
  },
];
const pagesAdmin = [
  {
    Nom: "Accueil",
    Chemin: "/accueil",
  },
  {
    Nom: "Planning",
    Chemin: "/planning",
  },
  {
    Nom: "Inscription",
    Chemin: "/inscription",
  },
  {
    Nom: "Historique",
    Chemin: "/historique",
  },
  {
    Nom: "Administration",
    Chemin: "/admin",
  },
];
const settings = [
  {
    Nom: "Mon profil",
    Chemin: "/profil",
  },
  {
    Nom: "Déconnexion",
    Chemin: "/login",
  },
];

export default function ResponsiveAppBar() {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [roleData, setRoleData] = useState([]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    fetchWithInterceptors(`${API_URL}/roles/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((data) => {
        if (data) {
          setRoleData(data);
        } else {
          toast.error("Une erreur est survenue");
          localStorage.clear();
          setTimeout(() => {
            navigate("/login");
          }, "1500");
        }
      })
      .catch((error) => console.error("Error:", error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeOfUserLogged = localStorage.getItem("user_role");

  const [isUser, setIsUser] = useState();
  const [isCoach, setIsCoach] = useState();
  const [isAdmin, setIsAdmin] = useState();

  useEffect(() => {
    if (roleData && roleData.length >= 1) {
      setIsUser(roleData[0].role_id);
      setIsCoach(roleData[1].role_id);
      setIsAdmin(roleData[2].role_id);
    }
  }, [roleData]);

  const [whoIsIt, setWhoIsIt] = useState(null);

  useEffect(() => {
    setWhoIsIt(localStorage.getItem("user_id"));
  }, []);

  const [username, setUsername] = useState("");
  const [userAge, setUserAge] = useState();

  useEffect(() => {
    if (whoIsIt) {
      fetchWithInterceptors(`${API_URL}/users/${whoIsIt}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((data) => {
          if (data) {
            setUsername(data.firstname + " " + data.pseudo);
            setUserAge(data.age);
          } else {
            toast.error("le nom d'utilisateur n'a pas pu être récupéré");
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  }, [whoIsIt]);

  const [userIsLogged, setUserIsLogged] = useState(false);
  const [coachIsLogged, setCoachIsLogged] = useState(false);
  const [adminIsLogged, setAdminIsLogged] = useState(false);

  useEffect(() => {
    switch (typeOfUserLogged) {
      case isUser:
        if (!userIsLogged) {
          setUserIsLogged(true);
          setAdminIsLogged(false);
          setCoachIsLogged(false);
        }
        break;
      case isCoach:
        if (!userIsLogged) {
          setUserIsLogged(false);
          setAdminIsLogged(false);
          setCoachIsLogged(true);
        }
        break;
      case isAdmin:
        if (!userIsLogged) {
          setUserIsLogged(false);
          setAdminIsLogged(true);
          setCoachIsLogged(false);
        }
        break;
    }
  }, [typeOfUserLogged, isUser, isCoach, isAdmin, userIsLogged]);

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <AppBar
      position="static"
      enableColorOnDark
      sx={{
        backgroundColor: "white",
        color: "#23274F",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4z)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <a href="/accueil">
              <img src={LogoCFMobile} alt="logo" style={{ height: "50px" }} />
            </a>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {adminIsLogged
                ? pagesAdmin.map((page) => (
                    <MenuItem
                      key={page.Nom}
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to={page.Chemin}
                    >
                      <Typography textAlign="center">{page.Nom}</Typography>
                    </MenuItem>
                  ))
                : userIsLogged
                ? pages.map((page) => (
                    <MenuItem
                      key={page.Nom}
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to={page.Chemin}
                    >
                      <Typography textAlign="center">{page.Nom}</Typography>
                    </MenuItem>
                  ))
                : coachIsLogged
                ? pagesCoach.map((page) => (
                    <MenuItem
                      key={page.Nom}
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to={page.Chemin}
                    >
                      <Typography textAlign="center">{page.Nom}</Typography>
                    </MenuItem>
                  ))
                : null}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <a href="/accueil">
              <img src={LogoCFDesktop} alt="logo" style={{ height: "50px" }} />
            </a>
            {adminIsLogged
              ? pagesAdmin.map((page) => (
                  <MenuItem
                    key={page.Nom}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={page.Chemin}
                  >
                    <Typography textAlign="center">{page.Nom}</Typography>
                  </MenuItem>
                ))
              : userIsLogged
              ? pages.map((page) => (
                  <MenuItem
                    key={page.Nom}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={page.Chemin}
                  >
                    <Typography textAlign="center">{page.Nom}</Typography>
                  </MenuItem>
                ))
              : pagesCoach.map((page) => (
                  <MenuItem
                    key={page.Nom}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={page.Chemin}
                  >
                    <Typography textAlign="center">{page.Nom}</Typography>
                  </MenuItem>
                ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  alignSelf: "center",
                  padding: "0 10px",
                  color: "#23274F",
                  maxWidth: "180px",
                  textOverflow: "ellipsis",
                }}
              >
                {username}
              </Typography>
              <Tooltip title="Open settings">
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0, border: "1px #23274F solid" }}
                >
                  <Avatar
                    alt="Logo de la Préfécture de Police de Paris"
                    src={Logopp}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div
                  style={{
                    textAlign: "left",
                    padding: "5px 10px",
                    color: "#23274F",
                  }}
                >
                  <b>{username}</b>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    padding: "10px",
                    color: "#23274F",
                  }}
                >
                  {userAge} ans
                </div>
                <Divider />
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.Nom}
                    onClick={() => {
                      handleCloseUserMenu();
                      if (setting.Nom === "Déconnexion") {
                        logout();
                      }
                    }}
                    component={Link}
                    to={setting.Chemin}
                  >
                    <Typography textAlign="center">{setting.Nom}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Box>
        </Toolbar>
      </Container>
      <ToastContainer
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </AppBar>
  );
}
