// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanningGlobal_dialogue__KaqPo {
  margin: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.PlanningGlobal_dialogue_titre__NDRGY {
  background-color: #23274F;
  color: #fff;
  padding: 15px;
  font-size: 20px;
}

.PlanningGlobal_dialogue_content_text__E5Eb0 {
  font-size: 16px;
  line-height: 1.6;
  padding-top: 20px;
}
.PlanningGlobal_dialogue_content_text__E5Eb0 :not(:last-child) {
  margin-bottom: 15px;
}

.PlanningGlobal_dialogue_content_text__E5Eb0 b {
  color: #23274F;
  font-weight: bold;
}

.PlanningGlobal_dialogue_actions__MuJSq {
  border-top: 1px solid #eee;
  padding: 15px;
  text-align: right;
}

.PlanningGlobal_dialogue_actions_button__tHcRF {
  background-color: #ff3333;
  color: #fff;
  border: none;
  padding: 7px 15px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
  font-size: larger;
  font-weight: 500;
}

.PlanningGlobal_dialogue_actions_Button__lhS2s {
  background-color: #23274F;
  color: #fff;
  border: none;
  padding: 7px 15px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
  font-size: larger;
  font-weight: 500;
}

.PlanningGlobal_dialogue_actions__MuJSq button:hover,
.PlanningGlobal_dialogue_actions__MuJSq Button:hover {
  opacity: 0.9;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/PlanningGlobal/PlanningGlobal.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,yCAAA;EACA,gBAAA;AACJ;;AAEE;EACE,yBAAA;EACA,WAAA;EACA,aAAA;EACA,eAAA;AACJ;;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACI;EACE,mBAAA;AACN;;AAGE;EACE,cAAA;EACA,iBAAA;AAAJ;;AAGE;EACE,0BAAA;EACA,aAAA;EACA,iBAAA;AAAJ;;AAGE;EACE,yBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;;AAGE;EACE,yBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;;AAGE;;EAEE,YAAA;AAAJ","sourcesContent":[".dialogue {\n    margin: auto; \n    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); \n    overflow: hidden;\n  }\n  \n  .dialogue_titre {\n    background-color: #23274F; \n    color: #fff; \n    padding: 15px; \n    font-size: 20px;\n  }\n  \n  .dialogue_content_text {\n    font-size: 16px;\n    line-height: 1.6;\n    padding-top: 20px;\n  \n    :not(:last-child) {\n      margin-bottom: 15px;\n    }\n  }\n  \n  .dialogue_content_text b {\n    color: #23274F;\n    font-weight: bold;\n  }\n  \n  .dialogue_actions {\n    border-top: 1px solid #eee; \n    padding: 15px; \n    text-align: right; \n  }\n  \n  .dialogue_actions_button {\n    background-color: #ff3333; \n    color: #fff; \n    border: none; \n    padding: 7px 15px; \n    border-radius: 10px; \n    cursor: pointer;\n    margin-right: 10px; \n    font-size: larger;\n    font-weight: 500;\n  }\n  \n  .dialogue_actions_Button {\n    background-color: #23274F; \n    color: #fff; \n    border: none; \n    padding: 7px 15px; \n    border-radius: 10px; \n    cursor: pointer;\n    margin-right: 10px; \n    font-size: larger;\n    font-weight: 500;\n  }\n  \n  .dialogue_actions button:hover, \n  .dialogue_actions Button:hover {\n    opacity: 0.9;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogue": `PlanningGlobal_dialogue__KaqPo`,
	"dialogue_titre": `PlanningGlobal_dialogue_titre__NDRGY`,
	"dialogue_content_text": `PlanningGlobal_dialogue_content_text__E5Eb0`,
	"dialogue_actions": `PlanningGlobal_dialogue_actions__MuJSq`,
	"dialogue_actions_button": `PlanningGlobal_dialogue_actions_button__tHcRF`,
	"dialogue_actions_Button": `PlanningGlobal_dialogue_actions_Button__lhS2s`
};
export default ___CSS_LOADER_EXPORT___;
