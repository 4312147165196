// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_App__2\\+VHF {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
  -webkit-user-select: none;
          user-select: none;
  max-width: 100vw;
}
@media (max-width: 600px) {
  .App_App__2\\+VHF {
    margin-bottom: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EAAA,kBAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,gBAAA;AACF;AACE;EANF;IAOI,oBAAA;EAEF;AACF","sourcesContent":[".App {\n  width: fit-content;\n  min-width: 100%;\n  user-select: none;\n  max-width: 100vw;\n\n  @media (max-width: 600px) {\n    margin-bottom: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `App_App__2+VHF`
};
export default ___CSS_LOADER_EXPORT___;
