import style from "./CoachsList.module.scss";
import { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchWithInterceptors } from "../../../functions/fetchWithInterceptors";

const Coachs = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [rowData, setRowData] = useState([]);

  const formatDateToFrench = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  const [columnDefs] = useState([
    {
      headerName: "Prénom",
      field: "firstname",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Nom",
      field: "coach_lastname",
      sortable: true,
      filter: true,
      flex: 1,
    },
    { headerName: "Âge", field: "age", sortable: true, filter: true, flex: 1 },
    {
      headerName: "Date de naissance",
      field: "birthday",
      sortable: true,
      filter: true,
      flex: 1,
      valueFormatter: (params) => formatDateToFrench(params.value),
    },
    {
      headerName: "Lieu de naissance",
      field: "birthplace",
      sortable: true,
      filter: true,
      flex: 1,
    },
  ]);

  useEffect(() => {
    fetchWithInterceptors(`${API_URL}/coaches/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")} `,
      },
    })
      .then((data) => {
        data
          ? setRowData(data)
          : toast.error("Les données n'ont pas pu être récupérées");
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "40px",
      }}
    >
      <h2 className={style["session-title"]}>Liste des Coachs</h2>
      <h5 className={style["session-subtitle"]}>Visualisez les Coachs.</h5>
      <div
        className="ag-theme-material"
        style={{
          width: "80%",
          height: "400px",
          marginTop: "40px",
          border: "1px solid #23274f",
          "--ag-alpine-active-color": "#23274f",
          "--ag-data-color": "#23274f",
          "--ag-header-foreground-color": "#23274f",
          "--ag-header-background-color": "#f5f5f5",
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          animateRows={false}
        />
      </div>
      <ToastContainer
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};

export default Coachs;
