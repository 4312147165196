import { useState } from "react";
import style from "./AccessCode.module.scss";
import logo from "../../assets/logos/LogoCFDesktop.png";
import { Player } from "@lottiefiles/react-lottie-player";
import version from "./../../../package.json";

export function AccessCodePage({ onValidCode }) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [code, setCode] = useState("");

  const [showWhitePage, setShowWhitePage] = useState(false);

  const handleSubmit = () => {
    fetch(`${API_URL}/auth/auth/check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    })
      .then((data) => {
        if (data.status === 200) {
          setShowWhitePage(true);
          setTimeout(() => {
            onValidCode();
          }, 2000);
        } else {
          alert("Code invalide");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className={style["containercodeaccess"]}>
      {showWhitePage ? (
        <div
          style={{ width: "100vw", height: "100vh", backgroundColor: "white" }}
        >
          <Player
            autoplay
            loop
            src="/lottieValidation.json"
            style={{ height: "300px", width: "300px" }}
          />
        </div>
      ) : (
        <>
          <img
            className={style["imagelogo"]}
            src={logo}
            alt="logo de collecty'form"
          />
          <p>CoachingHub v{version.version}</p>
          <h1>Défi Sport Santé 2024</h1>
          <br />
          <br />
          {/* <img
            src={logopp}
            style={{ height: "120px" }}
            alt="logo de la préfecture de police de paris"
          /> */}
          <input
            className={style["inputcodeaccess"]}
            type="text"
            placeholder="Entrez le code d'accès"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <button className={style["buttonaccess"]} onClick={handleSubmit}>
            Soumettre
          </button>
          <br />
        </>
      )}
    </div>
  );
}
