import style from "./HistoryPage.module.scss";
import SessionsHistory from "../../components/HistoryPage/History";
import { useEffect } from "react";

export const History = () => {
  useEffect(() => {
    document.title = "Mon historique - CoachingHub";
  }, []);

  return (
    <main className={style["History"]}>
      <SessionsHistory />
    </main>
  );
};
