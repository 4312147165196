// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Admin_Admin__vZKfB {
  text-align: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Admin.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF","sourcesContent":[".Admin {\n  text-align: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Admin": `Admin_Admin__vZKfB`
};
export default ___CSS_LOADER_EXPORT___;
