import { useEffect } from "react";
import style from "./HomePage.module.scss";

import Introduction from "../../components/Homepage/Introduction/Introduction";
import PagesDetails from "../../components/Homepage/PagesDetails/PagesDetails";

export const HomePage = () => {
  useEffect(() => {
    document.title = "Accueil - CoachingHub";
  }, []);

  return (
    <main className={style.HomePage}>
      <Introduction />
      <PagesDetails />
    </main>
  );
};
