// InfoBanner.jsx
import style from "./InfoBanner.module.scss";

const InfoBanner = () => {
  return (
    <div className={style["info-banner"]}>
      <p>
        ✅ Votre retour nous est précieux !{" "}
        <a href="https://forms.gle/DabW8DyT2wAZa2a47">Prenez une minute et dîtes-nous !</a> ! ❤️
      </p> 
      {/* <p> 📢 Vous pouvez maintenant vous inscrire à plusieurs séances à l'avance ! Profitez-en ! </p> */}
    </div>
  );
};

export default InfoBanner;
