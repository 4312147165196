// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Introduction_header__V8Ilq {
  background: #23274f;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 18px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
.Introduction_header__V8Ilq h1 {
  font-size: 2.5rem;
  transition: transform 0.2s ease-in-out;
}
.Introduction_header__V8Ilq h1 span {
  color: white;
}
.Introduction_header__V8Ilq h1:hover {
  transform: scale(1.05);
}
.Introduction_header__V8Ilq .Introduction_logo__KOtTY {
  height: 1.65em;
  vertical-align: middle;
}

.Introduction_intro__hze66 {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  animation: Introduction_fadeIn__msrmf 1.5s ease-in-out;
}
@media screen and (max-width: 768px) {
  .Introduction_intro__hze66 {
    flex-direction: column;
    gap: 20px;
  }
}
.Introduction_intro__hze66 .Introduction_text__ij6h4 {
  flex: 1 1;
  padding-right: 20px;
}
.Introduction_intro__hze66 .Introduction_imageWrapper__x2CUl {
  flex: 1 1;
}
.Introduction_intro__hze66 .Introduction_imageWrapper__x2CUl .Introduction_image__C9pzE {
  max-width: 100%;
  border-radius: 18px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}
.Introduction_intro__hze66 .Introduction_imageWrapper__x2CUl .Introduction_image__C9pzE:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}`, "",{"version":3,"sources":["webpack://./src/components/Homepage/Introduction/Introduction.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,2CAAA;AACF;AACE;EACE,iBAAA;EACA,sCAAA;AACJ;AACI;EACE,YAAA;AACN;AAEI;EACE,sBAAA;AAAN;AAIE;EACE,cAAA;EACA,sBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,sDAAA;AAHF;AAKE;EANF;IAOI,sBAAA;IACA,SAAA;EAFF;AACF;AAIE;EACE,SAAA;EACA,mBAAA;AAFJ;AAKE;EACE,SAAA;AAHJ;AAKI;EACE,eAAA;EACA,mBAAA;EACA,2CAAA;EACA,gCAAA;AAHN;AAKM;EACE,sBAAA;EACA,2CAAA;AAHR","sourcesContent":[".header {\n  background: #23274f;\n  color: white;\n  padding: 20px;\n  text-align: center;\n  border-radius: 18px;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);\n\n  h1 {\n    font-size: 2.5rem;\n    transition: transform 0.2s ease-in-out;\n\n    span {\n      color: white;\n    }\n\n    &:hover {\n      transform: scale(1.05);\n    }\n  }\n\n  .logo {\n    height: 1.65em;\n    vertical-align: middle;\n  }\n}\n\n.intro {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 40px;\n  animation: fadeIn 1.5s ease-in-out;\n\n  @media screen and (max-width: 768px) {\n    flex-direction: column;\n    gap: 20px;\n  }\n\n  .text {\n    flex: 1;\n    padding-right: 20px;\n  }\n\n  .imageWrapper {\n    flex: 1;\n\n    .image {\n      max-width: 100%;\n      border-radius: 18px;\n      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);\n      transition: all 0.2s ease-in-out;\n\n      &:hover {\n        transform: scale(1.05);\n        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Introduction_header__V8Ilq`,
	"logo": `Introduction_logo__KOtTY`,
	"intro": `Introduction_intro__hze66`,
	"fadeIn": `Introduction_fadeIn__msrmf`,
	"text": `Introduction_text__ij6h4`,
	"imageWrapper": `Introduction_imageWrapper__x2CUl`,
	"image": `Introduction_image__C9pzE`
};
export default ___CSS_LOADER_EXPORT___;
