import { useState, useEffect } from "react";
import style from "./History.module.scss";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchWithInterceptors } from "../../functions/fetchWithInterceptors";

const SessionsHistory = () => {
  let user_id = localStorage.getItem("user_id");
  const API_URL = process.env.REACT_APP_API_URL;

  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    fetchWithInterceptors(
      `${API_URL}/sessions/registered/user/${user_id}/history/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")} `,
        },
      }
    )
      .then((data) => {
        data
          ? setSessions(data)
          : toast.error("Les données n'ont pas pu être récupérées");
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const [notAttended, setNotAttended] = useState([]);

  useEffect(() => {
    fetchWithInterceptors(
      `${API_URL}/sessions/registered/user/${user_id}/notattended/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")} `,
        },
      }
    )
      .then((data) => {
        data
          ? setNotAttended(data)
          : toast.error("Les données n'ont pas pu être récupérées");
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <div className={style["session-container"]}>
      <h1 className={style["session-title"]}>Historique des séances</h1>
      <h2 className={style["session-subtitle"]}>
        Visualisez les séances auxquelles vous avez participé.
      </h2>
      {notAttended.length > 0 ? (
        <p>
          Vous n&apos;avez pas été présent à{" "}
          <span className={style["session-not-attended"]}>
            {notAttended.length}
          </span>{" "}
          séance(s).
        </p>
      ) : sessions.length > 0 ? (
        <p>Vous avez été présent à toutes vos séances.</p>
      ) : null}
      {sessions.length > 0 ? (
        <div className={style["table-container"]}>
          <table>
            <thead>
              <tr>
                <th className={style["thead-title"]}>Titre</th>
                <th className={style["thead-title"]}>Date</th>
                <th className={style["thead-title"]}>Heure</th>
                <th className={style["thead-title"]}>Localisation</th>
              </tr>
            </thead>
            <tbody>
              {sessions?.map((session, index) => (
                <tr key={index} className={style["session-item"]}>
                  <td className={style["session-cell"]}>{session.title}</td>
                  <td className={style["session-cell"]}>
                    {new Date(session.date).toLocaleDateString()}
                  </td>
                  <td className={style["session-cell"]}>{session.time}</td>
                  <td className={style["session-cell"]}>{session.location}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Vous n&apos;avez pas encore participé à une séance.</p>
      )}
      <ToastContainer
        limit={1}
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};

export default SessionsHistory;
