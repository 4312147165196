// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Inscription_container__XTf-Y h4 {
  margin: 50px 0;
  text-align: center;
  font-size: 36px;
}
@media (max-width: 768px) {
  .Inscription_container__XTf-Y .Inscription_container__XTf-Y h4 {
    font-size: 28px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Inscription/Inscription.module.scss"],"names":[],"mappings":"AAGE;EACE,cAAA;EACA,kBAAA;EACA,eAAA;AAFJ;AAKE;EAEI;IACE,eAAA;EAJN;AACF","sourcesContent":["// Inscription.module.scss\n\n.container {\n  h4 {\n    margin: 50px 0;\n    text-align: center;\n    font-size: 36px;\n  }\n\n  @media (max-width: 768px) {\n    .container {\n      h4 {\n        font-size: 28px;\n      }\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Inscription_container__XTf-Y`
};
export default ___CSS_LOADER_EXPORT___;
