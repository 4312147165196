// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./SofiaProRegular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./SofiaProBold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Sofia Pro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}
body {
  margin: 0;
  font-family: "Sofia Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,2HAAA;AACF;AAGA;EACE,SAAA;EACA,oCAAA;EACA,mCAAA;EACA,kCAAA;AADF;;AAIA;EACE,SAAA;AADF;;AAIA;EACE,+EAAA;AADF","sourcesContent":["@font-face {\n  font-family: \"Sofia Pro\";\n  src: url('/SofiaProRegular.otf') format('opentype'),\n        url('/SofiaProBold.otf') format('opentype');\n}\n\nbody {\n  margin: 0;\n  font-family: \"Sofia Pro\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ndiv, p, h1, h2, h3, h4, h5, h6 {\n  margin: 0;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
