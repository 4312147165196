import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import style from "./Inscription.module.scss";
// import OrderButtons from "../../components/Inscription/OrderButons/OrderButtons";
import Cards from "../../components/Inscription/Cards/Cards";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import { fetchWithInterceptors } from "../../functions/fetchWithInterceptors";

const Inscription = () => {
  useEffect(() => {
    document.title = "S'inscrire - CoachingHub";
  }, []);

  const API_URL = process.env.REACT_APP_API_URL;
  const [sessions, setSessions] = useState([]);
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    fetchWithInterceptors(`${API_URL}/sessions/notregistered/user/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")} `,
      },
    })
      .then((data) => {
        if (data) {
          const sortedSessions = data.sort((a, b) => {
            return a.date.localeCompare(b.date) || a.time.localeCompare(b.time);
          });
          setSessions(sortedSessions);
        } else {
          toast.error("Les données n'ont pas pu être récupérées");
        }
      })
      .catch(() => console.error("Error"));
  }, [userId]);

  return (
    <div className={style.container}>
      <h4 style={{ margin: "50px 0", textAlign: "center", fontSize: "36px" }}>
        Consultez les prochaines séances où vous inscrire !
      </h4>
      <p style={{ margin: "0", textAlign: "center" }}>
        Trouvez la prochaine séance qui vous convient et inscrivez-vous ! Cet
        écran vous présente toutes les séances ultérieures avec des places
        disponibles.
      </p>
      {/* <OrderButtons sessions={sessions} setSessions={setSessions}/> */}
      <Cards sessions={sessions} />
      <ToastContainer
        limit={1}
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};

export default Inscription;
