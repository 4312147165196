// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignUp_container__uXgMl {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.SignUp_form__w5YT4 {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 300px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.SignUp_form__w5YT4 h2 {
  margin-bottom: 2rem;
  text-align: center;
  color: #23274f;
}
.SignUp_form__w5YT4 input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.SignUp_form__w5YT4 button {
  margin: 1rem 0;
  padding: 0.5rem;
  background-color: #23274f;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.SignUp_form__w5YT4 button:hover {
  background-color: #13163a;
}
.SignUp_form__w5YT4 .SignUp_links__EBQe7 {
  display: flex;
  justify-content: space-between;
}
.SignUp_form__w5YT4 .SignUp_links__EBQe7 a {
  color: #23274f;
  text-decoration: none;
}
.SignUp_form__w5YT4 .SignUp_links__EBQe7 a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/SignUp/SignUp.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,uCAAA;AACF;AACE;EACE,mBAAA;EACA,kBAAA;EACA,cAAA;AACJ;AAEE;EACE,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;AAAJ;AAGE;EACE,cAAA;EACA,eAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,sCAAA;AADJ;AAGI;EACE,yBAAA;AADN;AAKE;EACE,aAAA;EACA,8BAAA;AAHJ;AAKI;EACE,cAAA;EACA,qBAAA;AAHN;AAKM;EACE,0BAAA;AAHR","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #f2f2f2;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  padding: 2rem;\n  width: 300px;\n  background-color: white;\n  border-radius: 8px;\n  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);\n\n  h2 {\n    margin-bottom: 2rem;\n    text-align: center;\n    color: #23274f;\n  }\n\n  input {\n    margin-bottom: 1rem;\n    padding: 0.5rem;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n  }\n\n  button {\n    margin: 1rem 0;\n    padding: 0.5rem;\n    background-color: #23274f;\n    color: white;\n    border-radius: 4px;\n    border: none;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n\n    &:hover {\n      background-color: #13163a;\n    }\n  }\n\n  .links {\n    display: flex;\n    justify-content: space-between;\n\n    a {\n      color: #23274f;\n      text-decoration: none;\n\n      &:hover {\n        text-decoration: underline;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SignUp_container__uXgMl`,
	"form": `SignUp_form__w5YT4`,
	"links": `SignUp_links__EBQe7`
};
export default ___CSS_LOADER_EXPORT___;
