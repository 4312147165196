import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import style from "./ModalSession.module.scss";
import moment from "moment";
import "moment/locale/fr";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchWithInterceptors } from "../../functions/fetchWithInterceptors";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

moment.locale("fr");

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function ModalSession({
  open,
  handleCloseModal,
  selectedSession,
}) {
  const API_URL = process.env.REACT_APP_API_URL;
  let user_id = localStorage.getItem("user_id");
  let session_id = selectedSession ? selectedSession.session_id : null;
  let attended = false;
  const date = moment();
  let register_date_time = date.format("YYYY-MM-DD");
  const navigate = useNavigate();

  const handleSubscribe = () => {
    fetchWithInterceptors(`${API_URL}/registrations/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        register_date_time,
        attended,
        user_id,
        session_id,
      }),
    })
      .then((registrationData) => {
        if (registrationData.registration_id) {
          setTimeout(() => {
            navigate("/planning");
          }, [1500]);
          toast.success("Vous êtes inscrit.e à cette session");
          handleCloseModal();
        } else if (
          registrationData.error === "Vous êtes déjà inscrit à une séance"
        ) {
          toast.error("Vous êtes déjà inscrit.e à une séance (1 max / pers)");
        } else {
          toast.error("Une erreur est survenue, l'inscription a echouée");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const [lowSpotNumber, setLowSpotNumber] = useState(false);

  useEffect(() => {
    if (selectedSession && selectedSession.availablespots <= 4) {
      setLowSpotNumber(true);
    } else {
      setLowSpotNumber(false);
    }
  }, [selectedSession]);

  const [disabledButton, setDisabledButton] = useState(true);

  const enableButton = () => {
    if (disabledButton === true) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const [clickCount, setClickCount] = useState(0);

  const handleParticipationReminder = () => {
    if (!disabledButton) {
      handleSubscribe();
    } else {
      setClickCount((prevCount) => prevCount + 1);

      if (clickCount >= 1) {
        toast.warning(
          "Attention, n'oublies pas de confirmer que tu as pris connaissances des informations"
        );
        setClickCount(0);
      }
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} className={style["modal-content"]}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Détails de la séance
          </Typography>
          {selectedSession && (
            <div className={style.detailsContainer}>
              <Typography id="modal-modal-title" sx={{}} component="p">
                <b>Intitulé</b> : <span>{selectedSession.title}</span>
              </Typography>
              <Typography
                id="modal-modal-date"
                sx={{
                  margin: "20px 0 0 0",
                  color: "#23274f",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
                component="p"
              >
                <b>📅 Date</b> :{" "}
                <span>
                  {new Date(selectedSession.date).toLocaleDateString()}
                </span>
              </Typography>
              <Typography
                id="modal-modal-time"
                sx={{
                  margin: "20px 0 0 0",
                  color: "#23274f",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
                component="p"
              >
                <b>⏰ Heure de début</b> :{" "}
                <span>
                  {selectedSession.time.split(":")[0]}h
                  {selectedSession.time.split(":")[1]}
                </span>
              </Typography>
              <Typography
                id="modal-modal-location"
                sx={{
                  margin: "20px 0 0 0 ",
                  color: "#23274f",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
                component="p"
              >
                <b>📍 Lieu</b> : <span>{selectedSession.location}</span>
              </Typography>
              <Typography
                id="modal-modal-location"
                sx={{
                  margin: "20px 0 0 0 ",
                  color: "#23274f",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
                component="p"
              >
                <b>⏱️ Durée</b> : <span>{selectedSession.duration}</span>
              </Typography>
              <Typography
                id="modal-modal-availableSpaces"
                sx={{
                  margin: "20px 0 0 0",
                  color: "#23274f",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
                component="p"
              >
                <b>Places disponibles</b> :{" "}
                <span
                  className={
                    lowSpotNumber
                      ? style["lowNumberOfAvailableSpots"]
                      : style["highNumberOfAvailableSpots"]
                  }
                >
                  {selectedSession.availablespots}
                </span>
              </Typography>
            </div>
          )}
          <div className={style.submitBox}>
            <div className={style.modalInput}>
              <input type="checkbox" onChange={enableButton} />
              <label style={{ color: disabledButton ? "#EA535C" : "#23264F" }}>
                <strong>
                  J&apos;ai pris connaissance des informations ci-dessus
                </strong>
              </label>
            </div>
            <button
              className={style.submitButton}
              onClick={handleParticipationReminder}
            >
              Participer 🚀
            </button>
          </div>
        </Box>
      </Modal>
      <ToastContainer
        limit={1}
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
}
