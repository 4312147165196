import style from "./Admin.module.scss";
import Dashboard from "../../components/Admin/Dashboard/Dashboard";
import { useEffect } from "react";
import Users from "../../components/Admin/Users/Users";
import Sessions from "../../components/Admin/Sessions/Sessions";
import PlanningGlobal from "../../components/Admin/PlanningGlobal/PlanningGlobal";
import Coachs from "../../components/Admin/CoachsList/CoachsList";

export const AdminPage = () => {
  useEffect(() => {
    document.title = "Panneau d'administration - CoachingHub";
  }, []);
  return (
    <main className={style["Admin"]}>
      <p style={{ margin: "20px 0", color: "#ea535c" }}>
        <strong>
          Merci d&apos;utiliser cette page administration en plein écran, sur
          ordinateur.
        </strong>
      </p>
      <PlanningGlobal />
      <Dashboard />
      <Coachs />
      <Sessions />
      <Users />
    </main>
  );
};
