import style from "./Profil.module.scss";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchWithInterceptors } from "../../functions/fetchWithInterceptors";

export const Profil = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [profileData, setProfileData] = useState([]);
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    document.title = "Mon Profil - CoachingHub";
  }, []);

  const [updatedInfo, setUpdatedInfo] = useState({
    firstname: "",
    pseudo: "",
    age: "",
  });

  const handleUpdateFirstname = (e) => {
    setUpdatedInfo({ ...updatedInfo, firstname: e.target.value });
  };

  const handleUpdatepseudo = (e) => {
    setUpdatedInfo({ ...updatedInfo, pseudo: e.target.value });
  };

  const handleUpdateAge = (e) => {
    setUpdatedInfo({ ...updatedInfo, age: e.target.value });
  };

  const handlePostNewInfos = () => {
    fetchWithInterceptors(`${API_URL}/users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(updatedInfo),
    })
      .then((data) => {
        if (data) {
          setProfileData(data);
          setUpdatedInfo({
            firstname: data.firstname,
            pseudo: data.pseudo,
            age: data.age,
          });
          toast.success("Vos informations ont bien été changées");
          window.location.reload();
        } else {
          toast.error("Une erreur est survenue");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    fetchWithInterceptors(`${API_URL}/users/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((data) => {
        if (data) {
          setProfileData(data);
          setUpdatedInfo({
            firstname: data.firstname,
            pseudo: data.pseudo,
            age: data.age,
          });
        } else {
          toast.error("Vos informations n'ont pas pu être récupérées");
        }
      })
      .catch((error) => console.error("Error:", error));
  }, [userId]);

  return (
    <div className={style["Profil"]}>
      <h1>Mon Profil</h1>
      <p>Visualisez et modifiez ici vos informations personnelles.</p>
      <h2>Vos informations actuelles</h2>
      <p>
        <strong>Votre pseudo :</strong> {profileData.pseudo}
      </p>
      <p>
        <strong>Votre prénom :</strong> {profileData.firstname}
      </p>
      <p>
        <strong>Votre âge :</strong> {profileData.age}
      </p>
      <h2>Modifiez vos informations personnelles</h2>
      <div className={style["inputLabel"]}>
        <label htmlFor="nom">Nom</label>
        <input
          type="text"
          id="nom"
          name="nom"
          onChange={handleUpdatepseudo}
          placeholder={profileData.pseudo}
        />
      </div>
      <div className={style["inputLabel"]}>
        <label htmlFor="prenom">Prénom</label>
        <input
          type="text"
          id="prenom"
          name="prenom"
          onChange={handleUpdateFirstname}
          placeholder={profileData.firstname}
        />
      </div>
      <div className={style["inputLabel"]}>
        <label htmlFor="age">Age</label>
        <select name="age" required onChange={handleUpdateAge}>
          <option></option>
          <option value="18-25">18-25</option>
          <option value="25-30">25-30</option>
          <option value="30-35">30-35</option>
          <option value="35-40">35-40</option>
          <option value="40-45">40-45</option>
          <option value="45-50">45-50</option>
          <option value="50-55">50-55</option>
          <option value="55+">55+</option>
        </select>
      </div>
      <button onClick={handlePostNewInfos}>
        Enregistrer ces modifications
      </button>
      <ToastContainer
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};
