import style from './PagesDetails.module.scss'

const PagesDetails = () => {

  const pages = [
    {
      title: "🏠 Accueil (cette page)",
      description: "Vous êtes actuellement sur la page d'accueil, le point de départ de votre expérience sur CoachingHub. Ici, vous trouverez des informations essentielles sur la plateforme, y compris des guides et des conseils pour vous aider à maximiser l'efficacité de votre coaching."
    },
    {
      title: "🗓️ Planning",
      description: " L'onglet de planification est le cœur de votre parcours d'entraînement. Vous y verrez toutes les séances auxquelles vous êtes inscrit.e, ainsi que leur heure et leur emplacement. Vous pouvez également voir des séances recommandées basées sur vos préférences et votre historique."
    },
    {
      title: "🔎 Inscription",
      description: "L'onglet Inscription vous permet de naviguer à travers une large gamme de séances disponibles, allant du yoga à la musculation. Une fois que vous avez trouvé la séance qui vous convient, il suffit de cliquer sur le bouton 'S'inscrire' et vous serez ajouté à la liste des participants."
    },
    {
      title: "🕤 Historique",
      description: "Dans l'onglet Historique, vous pouvez consulter toutes les séances auxquelles vous avez assisté. Ce suivi vous permet de mesurer vos progrès et de consulter les notes ou les commentairesassociés à chaque séance. C'est un excellent moyen de suivre votre engagement et vos réalisations au fil du temps."
    },
    {
      title: "😃 Mon Profil",
      description: "L'onglet Mon Profil contient toutes les informations personnelles que vous avez fournies, y compris votre nom, votre adresse e-mail et vos préférences d'entraînement. Vous pouvez facilement mettre à jour ces informations en cliquant sur le bouton 'Modifier mon profil'."
    },
    {
      title: "❎ Déconnexion",
      description: "Pour vous déconnecter de la plateforme, il suffit de cliquer sur le bouton 'Déconnexion' situé en haut à droite de chaque page. Cela garantira la sécurité de votre compte lorsque vous avez terminé votre session."
    },
  ]

  return (
    <div>
      <section className={style.tabs}>
        <h2>Découvrez les différents onglets</h2>

        {pages && pages.map((element) => {
            return (
              <div className={style.tab} key={element.description}>
                <h3>{element.title}</h3>
                <p>{element.description}</p>
              </div>
            )
          })
        }
      </section>

      <div className={style.footer}>
        <h3>
          Prêt à commencer votre aventure ? <br />
        </h3>
        <button type="button" className={style.button}>
          <a href="/inscription">🚀 Inscrivez-vous à votre première séance! 🚀</a>
        </button>
      </div>
    </div>
  );
};

export default PagesDetails;