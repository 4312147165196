import { useState, useEffect } from "react";
import { BarLoader } from "react-spinners";

const LoadingComponent = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <h1>Chargement...</h1>
      <BarLoader color={"#23274F"} loading={true} size={24} />
      <br />
      <br />
      {showMessage && (
        <p>
          Si la plateforme ne charge pas,{" "}
          <a href="/accueil">retournez à l&apos;accueil</a>
        </p>
      )}
    </div>
  );
};

export default LoadingComponent;
