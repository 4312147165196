// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoBanner_info-banner__zH8J- {
  background-color: #23274f;
  text-align: center;
  padding: 15px 0;
  font-size: 1rem;
  font-weight: bold;
  color: white;
}
.InfoBanner_info-banner__zH8J- a {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/InfoBanner/InfoBanner.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;AACJ","sourcesContent":[".info-banner {\n  background-color: #23274f;\n  text-align: center;\n  padding: 15px 0;\n  font-size: 1rem;\n  font-weight: bold;\n  color: white;\n\n  a {\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-banner": `InfoBanner_info-banner__zH8J-`
};
export default ___CSS_LOADER_EXPORT___;
