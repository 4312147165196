import styles from "./SignUp.module.scss";
import logo from "./../../assets/logos/logo_cf.webp";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchWithInterceptors } from "../../functions/fetchWithInterceptors";

export const SignUpPage = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [age, setAge] = useState("");

  useEffect(() => {
    document.title = "Inscription - CoachingHub";
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Suppression des espaces avant et après les valeurs de firstname et pseudo
    const trimmedFirstname = firstname.trim();
    const trimmedPseudo = pseudo.trim();

    // Si l'une des valeurs est vide après avoir supprimé les espaces, affichez une erreur
    if (!trimmedFirstname || !trimmedPseudo) {
      toast.error(
        "Les champs Prénom et Pseudo ne peuvent pas être vides ou constitués uniquement d'espaces."
      );
      return;
    }

    if (trimmedFirstname.length < 2 || trimmedFirstname.length > 50) {
      toast.error("Le prénom est trop long ou trop court");
      return;
    }

    if (trimmedPseudo.length < 6 || trimmedFirstname.length > 50) {
      toast.error("Le pseudo est trop long ou trop court");
      return;
    }

    fetchWithInterceptors(`${API_URL}/auth/auth/signup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        firstname: trimmedFirstname,
        pseudo: trimmedPseudo,
        age,
      }),
    })
      .then((data) => {
        if (data.user_id) {
          toast.success("Compte créé ! Redirection...");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          toast.error("Erreur lors de la création du compte");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <img src={logo} alt="Logo de Collecty'form" />
        <h2>Création de compte</h2>
        <label htmlFor="prenom">Prénom</label>
        <input
          type="text"
          name="prenom"
          required
          onChange={(e) => setFirstname(e.target.value)}
        />
        <label htmlFor="pseudo">Pseudo</label>
        <input
          type="text"
          name="pseudo"
          required
          onChange={(e) => setPseudo(e.target.value)}
        />
        <label htmlFor="age">Age</label>
        <select name="age" required onChange={(e) => setAge(e.target.value)}>
          <option></option>
          <option value="18-25">18-25</option>
          <option value="25-30">25-30</option>
          <option value="30-35">30-35</option>
          <option value="35-40">35-40</option>
          <option value="40-45">40-45</option>
          <option value="45-50">45-50</option>
          <option value="50-55">50-55</option>
          <option value="55+">55+</option>
        </select>
        <p style={{ marginTop: "20px" }}>
          Veuillez bien retenir et noter vos identifiants. Aucun système de
          récupération par email n&apos;est actif.
        </p>
        <button type="submit">Créer mon compte</button>
        <div className={styles.links}>
          <a href="/login">J&apos;ai déjà un compte</a>
        </div>
        <a href="/mentions-legales">Mentions légales</a>
      </form>
      <ToastContainer
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};
