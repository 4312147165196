export const fetchWithInterceptors = async (url, options) => {
  try {
    const response = await fetch(url, options);
    const data = await response.json();

    if (data.error) {
      if (
        data.error === "Token expired" ||
        data.error.message === "Token expired"
      ) {
        localStorage.clear();
        window.location.href = "/loginAfterExpired";
        return null;
      }
    }

    return data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
