import styles from "./Login.module.scss";
import logo from "./../../assets/logos/LogoCFDesktop.png";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchWithInterceptors } from "../../functions/fetchWithInterceptors";

export const LoginPage = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState("");
  const [pseudo, setPseudo] = useState("");

  useEffect(() => {
    document.title = "Connexion - CoachingHub";
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Suppression des espaces avant et après les valeurs de firstname et pseudo
    const trimmedFirstname = firstname.trim();
    const trimmedPseudo = pseudo.trim();

    // Si l'une des valeurs est vide après avoir supprimé les espaces, affichez une erreur
    if (!trimmedFirstname || !trimmedPseudo) {
      toast.error(
        "Les champs Prénom et Pseudo ne peuvent pas être vides ou constitués uniquement d'espaces."
      );
      return;
    }

    fetchWithInterceptors(`${API_URL}/auth/auth/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        firstname: trimmedFirstname,
        pseudo: trimmedPseudo,
      }),
    })
      .then((data) => {
        if (data.user_id) {
          localStorage.clear;
          localStorage.setItem("user_id", data.user_id);
          localStorage.setItem("token", data.token);
          localStorage.setItem("user_role", data.role_id);
          toast.success("Identifiants corrects ! Connexion...");
          setTimeout(() => {
            navigate("/accueil");
          }, "2000");
        } else {
          toast.error(
            "Erreur lors de la connexion, vérifiez vos identifiants et réessayez"
          );
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <img src={logo} alt="Logo de Collecty'form" />
        <h2>Connexion</h2>

        <input
          type="text"
          name="firstname"
          placeholder="Prénom"
          required
          onChange={(e) => setFirstname(e.target.value)}
        />

        <input
          type="text"
          name="pseudo"
          placeholder="Pseudo"
          required
          onChange={(e) => setPseudo(e.target.value)}
        />
        <p>
          ⚠️ Veuillez bien retenir et noter vos identifiants. Aucun système de
          récupération par email n&apos;est actif. ⚠️
        </p>
        <button type="submit">Se connecter</button>

        <div className={styles.links}>
          <a href="/signup">Créer un compte</a>
        </div>
        <a href="/mentions-legales">Mentions légales</a>
      </form>
      <ToastContainer
        limit={1}
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};
