// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Users_sessions-history__pt1K7 .Users_session-container__EhOhn .Users_session-content__cz49T {
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}
.Users_sessions-history__pt1K7 .Users_session-container__EhOhn .Users_session-content__cz49T .Users_session-title__sVszO {
  color: #333;
  font-weight: bold;
  text-align: center;
  font-size: 1.25rem;
}
.Users_sessions-history__pt1K7 .Users_session-container__EhOhn .Users_session-content__cz49T .Users_session-subtitle__c6Eom {
  text-align: center;
}
.Users_sessions-history__pt1K7 .Users_session-container__EhOhn .Users_session-content__cz49T .Users_session-wrapper__O0lEc {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #ccc;
  border-radius: 1rem;
  max-height: 500px;
  overflow-y: scroll;
}
.Users_sessions-history__pt1K7 .Users_session-container__EhOhn .Users_session-content__cz49T .Users_session-wrapper__O0lEc .Users_session-table__NHius {
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.75rem;
}
.Users_sessions-history__pt1K7 .Users_session-container__EhOhn .Users_session-content__cz49T .Users_session-wrapper__O0lEc .Users_session-table__NHius .Users_session-header__jtxYH {
  background: #f5f5f5;
}
.Users_sessions-history__pt1K7 .Users_session-container__EhOhn .Users_session-content__cz49T .Users_session-wrapper__O0lEc .Users_session-table__NHius .Users_session-header__jtxYH .Users_session-cell__3Iy-d {
  padding: 6px;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #555;
}
.Users_sessions-history__pt1K7 .Users_session-container__EhOhn .Users_session-content__cz49T .Users_session-wrapper__O0lEc .Users_session-table__NHius .Users_session-body__1fLtg {
  background: #fff;
}
.Users_sessions-history__pt1K7 .Users_session-container__EhOhn .Users_session-content__cz49T .Users_session-wrapper__O0lEc .Users_session-table__NHius .Users_session-body__1fLtg tr {
  border-top: 1px solid #e5e7eb;
}
.Users_sessions-history__pt1K7 .Users_session-container__EhOhn .Users_session-content__cz49T .Users_session-wrapper__O0lEc .Users_session-table__NHius .Users_session-body__1fLtg tr .Users_session-cell-text__jqHiK {
  padding: 7px;
  font-size: 0.875rem;
  color: #555;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Users/Users.module.scss"],"names":[],"mappings":"AAEI;EACE,aAAA;EACA,WAAA;EACA,sBAAA;EACA,gBAAA;AADN;AAGM;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AADR;AAIM;EACE,kBAAA;AAFR;AAKM;EACE,uCAAA;EACA,6BAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;AAHR;AAKQ;EACE,WAAA;EACA,yBAAA;EACA,sBAAA;AAHV;AAKU;EACE,mBAAA;AAHZ;AAKY;EACE,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,qBAAA;EACA,WAAA;AAHd;AAOU;EACE,gBAAA;AALZ;AAOY;EACE,6BAAA;AALd;AAOc;EACE,YAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AALhB","sourcesContent":[".sessions-history {\n  .session-container {\n    .session-content {\n      padding: 2rem;\n      width: 100%;\n      box-sizing: border-box;\n      text-align: left;\n\n      .session-title {\n        color: #333;\n        font-weight: bold;\n        text-align: center;\n        font-size: 1.25rem;\n      }\n\n      .session-subtitle {\n        text-align: center;\n      }\n\n      .session-wrapper {\n        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n        border-bottom: 1px solid #ccc;\n        border-radius: 1rem;\n        max-height: 500px;\n        overflow-y: scroll;\n\n        .session-table {\n          width: 100%;\n          border-collapse: collapse;\n          border-radius: 0.75rem;\n\n          .session-header {\n            background: #f5f5f5;\n\n            .session-cell {\n              padding: 6px;\n              font-size: 0.75rem;\n              text-transform: uppercase;\n              letter-spacing: 0.1em;\n              color: #555;\n            }\n          }\n\n          .session-body {\n            background: #fff;\n\n            tr {\n              border-top: 1px solid #e5e7eb;\n\n              .session-cell-text {\n                padding: 7px;\n                font-size: 0.875rem;\n                color: #555;\n                white-space: nowrap;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sessions-history": `Users_sessions-history__pt1K7`,
	"session-container": `Users_session-container__EhOhn`,
	"session-content": `Users_session-content__cz49T`,
	"session-title": `Users_session-title__sVszO`,
	"session-subtitle": `Users_session-subtitle__c6Eom`,
	"session-wrapper": `Users_session-wrapper__O0lEc`,
	"session-table": `Users_session-table__NHius`,
	"session-header": `Users_session-header__jtxYH`,
	"session-cell": `Users_session-cell__3Iy-d`,
	"session-body": `Users_session-body__1fLtg`,
	"session-cell-text": `Users_session-cell-text__jqHiK`
};
export default ___CSS_LOADER_EXPORT___;
