import { useState, useEffect } from "react";
import style from "./PlanningGlobal.module.scss";
import { Calendar, momentLocalizer } from "react-big-calendar";
// eslint-disable-next-line
import moment, { locale } from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMediaQuery } from "react-responsive";
// eslint-disable-next-line
import "moment/locale/fr";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchWithInterceptors } from "../../../functions/fetchWithInterceptors";
// eslint-disable-next-line
moment.locale("fr");

const PlanningGlobal = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const localizer = momentLocalizer(moment);
  // eslint-disable-next-line no-unused-vars
  const [plannerData, setPlannerData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [usersGet, setUsersGet] = useState([]);
  const [sessionsGet, setSessionsGet] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventCoach, setEventCoach] = useState(null);

  useEffect(() => {
    document.title = "Planning Global - CoachingHub";
  }, []);

  // GET /sessions: Récupérer la liste des sessions
  // puis GET /registration/session/{id}: Récupérer la liste des inscriptions pour une session spécifique sur chacune des sessions
  // puis GET /user/{id}: Récupérer les informations d'un utilisateur (pour chaque utilisateur inscrit à chaque session)
  // Afficher les sessions dans le calendrier

  const fetchInformations = async () => {
    try {
      let sessions = await fetchWithInterceptors(`${API_URL}/sessions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")} `,
        },
      });

      if (!sessions) {
        toast.error("Les données n'ont pas pu être récupérés");
        return;
      }
      setPlannerData(sessions);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Les données n'ont pas pu être récupérés");
    }
  };

  useEffect(() => {
    fetchInformations();
    // eslint-disable-next-line
  }, []);

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });

  const events = sessionsGet.map((session) => {
    const datePart = session.date.split("T")[0];
    const startTime = new Date(datePart + "T" + session.time);
    return {
      title: `${session.title} - ${session.location}`,
      start: startTime,
      end: new Date(startTime.getTime() + session.duration * 60 * 1000),
      allDay: false,
      resource: session,
      availableSpots: session.availablespots,
    };
  });

  useEffect(() => {
    plannerData.length > 0 &&
      plannerData.map((plan) => {
        fetchWithInterceptors(`${API_URL}/sessions/${plan.session_id}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then((data2) => {
          if (data2) {
            setSessionsGet((previousSessions) => {
              if (
                previousSessions.some(
                  (session) => session.session_id === data2.session_id
                )
              ) {
                return previousSessions;
              } else {
                return [...previousSessions, data2];
              }
            });
          } else {
            toast.error("Les données n'ont pas pu être récupérées");
          }
        });
      });
  }, [plannerData]);

  useEffect(() => {
    fetchWithInterceptors(
      `${API_URL}/coaches/${selectedEvent?.resource.coach_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((data_coach) => {
      setEventCoach(data_coach);
    });
  }, [selectedEvent]);

  const handleOpen = (event) => {
    setSelectedEvent(event);
    setOpen(true);

    const currentEventId = event.resource.session_id;

    fetchWithInterceptors(`${API_URL}/sessions/${currentEventId}/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")} `,
      },
    }).then((data) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          fetchWithInterceptors(`${API_URL}/users/${data[i].user_id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")} `,
            },
          }).then((data2) => {
            setRowData((previousUser) => {
              if (previousUser.some((user) => user.user_id === data2.user_id)) {
                return previousUser;
              } else {
                return [...previousUser, data2];
              }
            });
          });
        }
      }
    });
  };

  const EventComponent = ({ event }) => {
    const { title, location } = event.resource;
    return (
      <div>
        <div>{title}</div>
        <div>{location}</div>
      </div>
    );
  };

  const handleClose = () => {
    setRowData([]);
    setOpen(false);
  };

  return (
    <div className={style["PlanningGlobal"]}>
      <h2>Planning Global</h2>
      <Calendar
        popup
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView={isMobile ? "agenda" : isTablet ? "week" : "month"}
        min={new Date(0, 0, 0, 8, 0)}
        style={{ height: "70vh", margin: "20px" }}
        onSelectEvent={handleOpen}
        components={{
          event: EventComponent,
        }}
      />
      <Dialog open={open} onClose={handleClose} className={style.dialogue}>
        <DialogTitle className={style.dialogue_titre}>
          {selectedEvent?.title}
        </DialogTitle>
        <DialogContent className={style.dialogue_content}>
          <DialogContentText className={style.dialogue_content_text}>
            <b>Description</b> : {selectedEvent?.resource.description}
            <br />
            <b>📅 Date</b> :{" "}
            {new Date(selectedEvent?.resource.date).toLocaleDateString()}
            <br />
            <b>⏰ Heure de début</b> :{" "}
            {selectedEvent?.resource.time.split(":")[0]}h
            {selectedEvent?.resource.time.split(":")[1]}
            <br />
            <b>📍 Lieu</b> : {selectedEvent?.resource.location}
            <br />
            <b>⏱️ Durée</b> : {selectedEvent?.resource.duration} minutes
            <br />
            <b>🧢 Coach</b> : {eventCoach?.firstname}{" "}
            {eventCoach?.coach_lastname}
            <br />
            📞 Contact Collecty&apos;form : Alexandre / 06 02 37 21 37
            <br />
            📞 Contact sur site :{" "}
            {selectedEvent?.resource.contact
              ? selectedEvent?.resource.contact
              : "Non renseigné"}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="ag-theme-alpine"
                style={{
                  height: "100%",
                  width: "80%",
                  marginTop: "40px",
                  "--ag-alpine-active-color": "#23274f",
                  "--ag-data-color": "#23274f",
                  "--ag-header-foreground-color": "#23274f",
                }}
              >
                <h2 className={style["session-title"]}>
                  Participants inscrits
                </h2>
                <h5 className={style["session-subtitle"]}>
                  Visualisez les participants inscrits à la session.
                </h5>
                {rowData &&
                  rowData.map((element) => {
                    return (
                      <form key={element.firstname}>
                        <table>
                          <thead>
                            <tr>
                              <th>Prénom</th>
                              <th>Pseudo</th>
                              <th>Âge</th>
                              <th>Présent</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{element.firstname}</td>
                              <td>{element.pseudo}</td>
                              <td>{element.age}</td>
                            </tr>
                          </tbody>
                        </table>
                      </form>
                    );
                  })}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={style.dialogue_actions}>
          <button
            className={style.dialogue_actions_Button}
            onClick={handleClose}
          >
            Fermer
          </button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="bottom-center"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};

export default PlanningGlobal;
